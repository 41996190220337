import React from "react"
import { kebabCase } from "lodash";
import logo from "../../content/assets/img/logo.png"
import iconClose from "../../content/assets/img/icon-close.svg"
import { AiFillLinkedin } from "react-icons/ai"
import { Link } from "gatsby"
import "./mobile-menu.css"
import LanguageSwitcher from "./languageSwitcher"
import slugHandler from "../api/slugHandlerBrowser";

function MobileMenu(props) {
    let jobs = props.jobs;
    let listJobs = props.listJobs;
    let nav = props.translation.nav;
    let classes = "bot-mobile-menu color-black" + (props.isVisible ? "" : " bot-mobile-menu--invisible")
    return (
        <section className={classes}>
            <div className="container-800 posr">
            <div className="bot-menu-in bot-menu-in--mobile">
                <img src={logo} alt="" className="bot-menu-logo bot-mobile-menu-logo"></img>
                <div className="bot-menu-icons">
                    <div className="bot-menu-icon bot-icon-linkedin df">
                        <a href={props.settings.linkedin} target="_blank" rel="noreferrer">
                            <AiFillLinkedin className="db"></AiFillLinkedin>
                        </a>
                    </div>
                    <div className="bot-menu-icon bot-menu-langs">
                        <LanguageSwitcher activeLanguage={props.language || "en"} languages={props.languages} slug={props.slug}></LanguageSwitcher>
                    </div>
                    <div className="bot-menu-icon">
                    <button className="bot-mobile-menu-close" onClick={props.onClose}>
                        <img src={iconClose} alt="close"/>
                    </button>
                        </div>
                        </div>
                </div>
                <div className="df jcc">
                    <div className="bot-mobile-menu-list">
                        <h4><Link to={nav[0].url} className="bot-mobile-menu-item" activeClassName="bot-mobile-menu-item--active" onClick={props.onClose}>{nav[0].title}</Link></h4>
                        <h4><Link to={nav[1].url} className="bot-mobile-menu-item" onClick={props.onClose}>{nav[1].title}</Link></h4>
                            {
                                props.services && props.services.map((service, i)=>{
                                    return (
                                        <p key={i} className="bot-mobile-menu-item bot-mobile-menu-item-subtitle">
                                            <Link to={slugHandler(props.language, "")+"#"+kebabCase(service.title)} onClick={props.onClose}>{service.title}</Link>
                                        </p>
                                    )
                                })
                            }
                        <h4><Link to={nav[2].url} className="bot-mobile-menu-item" onClick={props.onClose}>{nav[2].title}</Link></h4>
                            {
                                jobs.list && jobs.list.length && jobs.list.map((link, i)=>{
                                    let job = listJobs.filter((j)=>j.frontmatter.slug === `${link.position}` && j.frontmatter.enabled)[0];
                                    return job && (
                                            <p key={i} className="bot-mobile-menu-item bot-mobile-menu-item-subtitle">
                                                 <Link to={slugHandler(props.language, "")+"#"+kebabCase(job.frontmatter.slug)} onClick={props.onClose}>{job.frontmatter.title}</Link>
                                             </p>
                                            )
                                })
                                // jobs && jobs.filter((job)=>job.frontmatter.enabled).map((job, i)=>{
                                //     return(
                                //     <p key={i} className="bot-mobile-menu-item bot-mobile-menu-item-subtitle">
                                //         <Link to={slugHandler(props.language, "")+"#"+kebabCase(job.frontmatter.slug)} onClick={props.onClose}>{job.frontmatter.title}</Link>
                                //     </p>
                                //     )
                                // })
                            }
                        {/* <h4><Link to={nav[3].url} className="bot-mobile-menu-item" onClick={props.onClose}>{nav[3].title}</Link></h4> */}
                        <h4><Link to={nav[4].url} className="bot-mobile-menu-item" onClick={props.onClose}>{nav[4].title}</Link></h4>
                    </div>
                </div>
             
           


</div>
        </section>

    )
}


export default MobileMenu